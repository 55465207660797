<template>
  <div class="app-container">
    <div class="main">
      <section>
        <div class="title">「税务登记及一般纳税人资格」信息获取方法</div>
        <p>方法一：通过全国企业一般纳税人资格查询网，获取公司纳税资质证明： </p>
        <p>1. 点击进入（如下图）：
          <a href="http://www.foochen.com/zty/ybnsr/yibannashuiren.html" target="_blank">
            http://www.foochen.com/zty/ybnsr/yibannashuiren.html
          </a>
        </p>
      </section>
    </div>
    <img src="https://qkodo.vfinemusic.com/assets/common/202109/246700cb9c2655437f001.png" alt="">
    <div class="main">
      <section>
        <p>2. 如上图所示，点击企业所在地，页面将进入「税务登记及一般纳税人资格查询」页，如下图： </p>
      </section>
    </div>
    <img src="https://qkodo.vfinemusic.com/assets/common/202109/246700cb9e4ff2937f001.png" alt="">
    <div class="main">
      <section>
        <p>3. 填写正确的纳税人识别号、验证码，点击「查询」按钮，页面刷新展示查询结果，如下图</p>
      </section>
    </div>
    <img src="https://qkodo.vfinemusic.com/assets/common/202109/246700cb9e57be637f001.png" alt="">
    <div class="main">
      <section>
        <p>4. 点击上图所示位置，即可在新页面获得「税务登记及一般纳税人资格信息」，如下图：</p>
      </section>
    </div>
    <img src="https://qkodo.vfinemusic.com/assets/common/202109/246700cb9e5c56437f001.png" alt="">
    <div class="main">
      <section>
        <p>5. 截图保存，并上传至 PlayList 开票环节上传一般纳税人资质，如下图：</p>
      </section>
    </div>
    <img src="https://qkodo.playlistmusic.com.cn/%E4%B8%80%E8%88%AC%E7%BA%B3%E7%A8%8E%E4%BA%BA%E8%B5%84%E8%B4%A8.jpg" alt="">
    <div class="main">
      <section>
        <p>方法二、如企业所在地为辽宁(不含大连)、吉林、黑龙江、安徽、西藏，请直接拍照上传「企业税务登记及一般纳税人资格信息」，如下图所示：</p>
      </section>
    </div>
    <img src="https://qkodo.vfinemusic.com/assets/common/202109/246700cb9e635ea37f001.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'License'
}
</script>

<style lang="scss" scoped>
a{
  color: #0061F3;
}
.app-container {
  width: 1220px;
  margin: 0 auto;
  min-height: calc(100vh - 268px);
  padding-bottom: 80px;
  @include center-middle-flex;
  flex-direction: column;
  img {
    max-width: 1220px;
    max-height: 1000px;
    border-radius: 12px;
  }
  .main {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(33, 48, 83, 0.07);
    border-radius: 12px;
    padding: 30px;
    margin: 90px 0;
    section {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
        margin-bottom: 36px;
        text-align: center;
      }
    }
  }
}
</style>
